.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .header {
    .navbar-nav .nav-link {
      padding-left: $navbar-padding-x / 2;
      padding-right: $navbar-padding-x / 2;
    }
  }
  .carousel-slider {
    .carousel-item {
      .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
      }
      .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
      }
      .img-fluid {
        height: auto;
      }
    }
    .carousel-indicators {
      margin-left: 6%;
    }
  }
  .find-health {
    .col-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
      flex: 0 0 60%;
      max-width: 60%;
    }
  }
  .communityPage {
    .ui.stackable.grid {
      width: 90% !important;
    }
  }
}

// Extra small devices (portrait phones, less than 576px) (landscape phones, 576px and up)
@media (min-width: 320px) and (max-width: 568px) {
  .hot-today-carousel .card-hot-today img {
    width: 100%;
  }
  .footer-navbar {
    .navbar-nav {
      .nav-link {
        font-size: 10px;
      }
    }
  }
}
