// Border
.border {
  border: $border-width solid $border-color !important;
}
.border-top {
  border-top: $border-width solid $border-color !important;
}
.border-right {
  border-right: $border-width solid $border-color !important;
}
.border-bottom {
  border-bottom: $border-width solid $border-color !important;
}
.border-left {
  border-left: $border-width solid $border-color !important;
}

.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.border-white {
  border-color: $white !important;
}

// Border-radius
.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}

.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: $rounded-pill !important;
}

.rounded-card {
  border-radius: $border-radius-card !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top-lef-right {
  position: relative;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
  box-shadow: $box-shadow-xl;
  @include border-top-radius($modal-content-inner-border-radius);
}

.rounded-bottom-lef-right {
  position: relative;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
  box-shadow: $box-shadow-xl;
  @include border-bottom-radius($modal-content-inner-border-radius);
}

.footer-top-lef-right {
  position: relative;
  padding: $spacer $spacer / 3;
  box-shadow: $box-shadow-xl;
  @include border-top-radius($modal-content-inner-border-radius);
  &.footer-shadow {
    box-shadow: 0 -1px 10px rgba($black, 0.16) !important;
  }
}

.rounded-modal-bottom-lef-right {
  position: relative;
  @include border-bottom-radius($modal-content-inner-border-radius);
}

.rounded-header-bottom-lef-right {
  position: relative;
  box-shadow: $box-shadow-xl;
  @include border-bottom-radius($modal-content-inner-border-radius);
}

.bg-light-blue-rounded-bottom-lef-right {
  position: relative;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
  @include border-bottom-radius($modal-content-inner-border-radius);
}

.bg-light-blue-rounded-top-lef-right {
  position: relative;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
  @include border-top-radius($modal-content-inner-border-radius);
}

.inner-cycle-gray {
  padding: $spacer * 2;
}

.rounded-circle-bg {
  width: 82px;
  height: 82px;
  line-height: 82px;
  background-color: $blue-dark;
}

.rounded-circle-blue-bg {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: auto;
  background-color: rgba($cyan, 0.5);
  box-shadow: $box-shadow-card;
}

.rounded-circle-white-bg {
  width: 56px;
  height: 56px;
  line-height: 56px;
  margin: 0 auto;
  background-color: $white;
  box-shadow: $box-shadow-card;
  border: (1 * $border-width) solid $white;
  &:hover,
  &.active {
    cursor: pointer;
    border: (2 * $border-width) solid rgba(#86E3CE, 0.5);
  }
  &~p {
    word-break: break-word;
    width: 63px;
    margin: 0 auto;
  }
}

.rounded-shape {
  width: 80px;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.rounded-shape-center {
  width: 90px;
  min-height: 72px;
  padding: $spacer 0;
  box-shadow: 0px 15px 20px $light-yellow;
}

.rounded-shape,
.rounded-shape-center {
  &.bg-gray-light {
    background-color: $gray-200;
  }
  &.bg-light-cyan {
    background-color: $light-cyan;
  }
  &.bg-light-gold-400 {
    background-color: $light-gold-400;
  }
}

.cllipse-circle {
  width: 72px;
  height: 72px;
  margin: auto;
  position: relative;
  &::after {
    width: 62px;
    height: 62px;
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    border-radius: $rounded-pill;
    background-color: $light-gold;
  }
  span {
    position: relative;
    top: 16px;
    left: 4px;
    font-weight: $font-weight-lg;
    font-size: $font-size-lg;
    line-height: 15px;
    letter-spacing: 1px;
  }
}

.border-align-center {
  position: relative;
  .border-line {
    position: absolute;
    width: 100%;
    height: 1px;
    top: 18px;
    border-bottom: (1 * $border-width) solid rgba($black, 0.1);
  }
}

.rounded-fast-circle-bg {
  width: 82px;
  height: 82px;
  line-height: 82px;
  background-color: $light-blue;
}
