.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  @include clearfix();
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  @include transition($carousel-transition);
  .img-fluid {
    width: auto;
    height: 100%;
    max-width: 100%;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

// Alternate transitions
.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
  }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }

  .active.carousel-item-left,
  .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    @include transition(opacity 0s $carousel-transition-duration);
  }
}

// Left/right controls for nav
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $carousel-control-width;
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-opacity;
  @include transition($carousel-control-transition);

  // Hover/focus state
  @include hover-focus() {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: $carousel-control-hover-opacity;
  }
}
.carousel-control-prev {
  left: 0;
  @if $enable-gradients {
    background-image: linear-gradient(90deg, rgba($black, 0.25), rgba($black, 0.001));
  }
}
.carousel-control-next {
  right: 0;
  @if $enable-gradients {
    background-image: linear-gradient(270deg, rgba($black, 0.25), rgba($black, 0.001));
  }
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: no-repeat 50% / 100% 100%;
}
.carousel-control-prev-icon {
  background-image: escape-svg($carousel-control-prev-icon-bg);
}
.carousel-control-next-icon {
  background-image: escape-svg($carousel-control-next-icon-bg);
}

// Optional indicator pips
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    border-radius: $rounded-pill;
    text-indent: -999px;
    cursor: pointer;
    background-color: $carousel-indicator-active-bg;
    background-clip: padding-box;
    //border-top: $carousel-indicator-hit-area-height solid transparent;
    //border-bottom: $carousel-indicator-hit-area-height solid transparent;
    opacity: 0.5;
    @include transition($carousel-indicator-transition);
  }

  .active {
    opacity: 1;
  }
}

// Optional captions
.carousel-caption {
  position: relative;
  right: (100% - $carousel-caption-width) / 2;
  left: (100% - $carousel-caption-width) / 55;
  z-index: 10;
  //top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  h1,
  h2,
  h3 {
    font-size: $font-size-base;
    font-weight: $font-weight-lg !important;
  }
  p {
    font-size: $font-size-small;
  }
}

.carousel-slider {
  padding: $spacer / 3 0rem;
  border-bottom-left-radius: $border-radius-album;
  border-bottom-right-radius: $border-radius-album;
  box-shadow: $box-shadow-xl;
  background-color: $white;
  .carousel-item {
    padding-top: $spacer;
    padding-bottom: $spacer;
    .carousel-img {
      width: 100%;
      height: 100%;
      text-align: center;
      img {
        width: 100%;
        height: 115px;
      }
    }
  }
  .slick-dots li {
    width: auto !important;
    margin: 0rem !important;
    button {
      width: 12px;
      height: 0rem;
      padding: 0rem;
    }
  } 
}

.related-articles-carousel {
  .owl-item img {
    height: 230px;
  }
}

.hot-today-carousel {
  height: 300px;
  overflow: hidden;
  .card-hot-today {
    position: relative;
    img {
      height: 300px;
    }
    .project-text {
      position: absolute;
      right: 1.5rem;
      bottom: 1.5rem;
      left: 1.5rem;
      background-color: $white;
      box-shadow: $box-shadow-card;
      border-radius: $border-radius-md;
    }
  }
}
