//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
  margin-bottom: $spacer * 1.5;

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: $card-spacer-x;
  color: $card-color;
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -$card-spacer-y / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover() {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}

//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-spacer-x / 2;
  margin-bottom: -$card-spacer-y;
  margin-left: -$card-spacer-x / 2;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -$card-spacer-x / 2;
  margin-left: -$card-spacer-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0; // For IE: https://github.com/twbs/bootstrap/issues/29396
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
  @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius($card-inner-border-radius);
}

// Card deck

.card-deck {
  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}

//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

//
// Accordion
//

.accordion {
  > .card {
    overflow: hidden;

    &:not(:last-of-type) {
      border-bottom: 0;
      @include border-bottom-radius(0);
    }

    &:not(:first-of-type) {
      @include border-top-radius(0);
    }

    > .card-header {
      @include border-radius(0);
      margin-bottom: -$card-border-width;
    }
  }
}

// album
.card-album,
.card-box {
  .card-img-top {
    @include border-album-top-radius($card-album-border-radius);
  }
  .card-img-bottom {
    @include border-album-bottom-radius($card-album-border-radius);
  }
}

.card-box {
  border: transparent;
}

.card-album {
  border: transparent;
  &.card-space {
    border: (5 * $border-width) solid $white;
  }
  img {
    width: 100%;
  }
  .figure {
    -webkit-box-pack: center;
    -webkit-box-align: center;
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .heading {
    font-size: $font-size-msmall;
    color: $gray-900;
    font-weight: $font-weight-lg;
    a {
      &:hover {
        text-decoration: $link-decoration;
      }
    }
  }
  .card-body {
    min-height: auto;
  }
  .card-text {
    color: $gray-600;
  }
}

.card-flower,
.card-blue,
.card-green,
.card-light-gold-400,
.card-light-cyan,
.card-light-gold,
.card-white,
.card-cyan-dark {
  overflow: hidden;
  padding: $spacer;
  border-radius: $border-radius-album;
  border: (5 * $border-width) solid $white;
  box-shadow: $box-shadow-card;
}

.card-green {
  background-color: $light-green;
}

.card-light-gold-400 {
  background-color: $light-gold-400;
}

.card-light-gold {
  background-color: $light-gold;
}

.card-light-cyan {
  background-color: $light-cyan;
}

.card-white {
  background-color: $white;
}

.card-cyan-dark {
  background-color: $cyan-dark;
}

.card-list-group-item {
  overflow: hidden;
  box-shadow: $box-shadow-card;
  border-radius: $border-radius-album;
  .list-group-item {
    border: none;
    box-shadow: $box-shadow-card;
    border-bottom: $list-group-border-width solid $list-group-border-color;
    &:hover {
      box-shadow: none;
    }
  }
}

.card-listener {
  overflow: hidden;
  border-radius: $border-radius-album;
  border: (1 * $border-width) solid $white;
  box-shadow: $box-shadow-card;
}

.card-center {
  width: 100%;
  max-width: 100%;
  padding: $spacer;
  margin: auto;
  height: 100%;
  border: none !important;
}

.find-health-card {
  min-height: 172px;
}
