//
// Rotating border
//

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  border: $spinner-border-width solid currentColor;
  border-right-color: transparent;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}

//
// Growing circle
//

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  background-color: currentColor;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
}

// Loader CSS here
.spinnerLoaderWrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f1fcff;
}

.spinner {
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: #f78579;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: #f78579;
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: spinner 1s ease-in-out infinite;
  animation: spinner 1s ease-in-out infinite;
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
