// Reboot
*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
  -webkit-tap-highlight-color: rgba($black, 0); // 5
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

// Body
body {
  font-size: $font-size-msmall;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left; // 3
  margin: 0; // 1
  overflow-x: hidden;
  font-family: $font-family-base;
  background-color: $body-bg; // 2
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}


// Content grouping
hr {
  box-sizing: content-box; // 1
  height: 0; // 1
  overflow: visible; // 2
}

// Typography
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}

// Reset margins on paragraphs
p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  &:last-child {
    margin-bottom: 0rem;
    margin-block-end: 0em;
  }
}

// Abbreviations
abbr[title],
abbr[data-original-title] { // 1
  text-decoration: underline; // 2
  text-decoration: underline dotted; // 2
  cursor: help; // 3
  border-bottom: 0; // 4
  text-decoration-skip-ink: none; // 5
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: $dt-font-weight;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: $font-weight-bolder; 
}

small {
  @include font-size(80%);
}

// Prevent `sub` and `sup` elements from affecting the line height in
sub,
sup {
  position: relative;
  @include font-size(75%);
  line-height: 0;
  vertical-align: baseline;
}

sub { bottom: -.25em; }
sup { top: -.5em; }

// Links
a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;

  @include hover() {
    color: inherit;
    text-decoration: none;
  }
}

// Code
pre,
code,
kbd,
samp {
  font-family: $font-family-monospace;
  @include font-size(1em);
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

// Figures
figure {
  margin: 0 0 1rem;
}

// Images and content
img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

// Tables
table {
  border-collapse: collapse;
}

caption {
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
  color: $table-caption-color;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

// Forms
label {
  display: inline-block;
  margin-bottom: $label-margin-bottom;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  @include font-size(inherit);
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

// Opinionated: add "hand" cursor to non-disabled button elements.
@if $enable-pointer-cursor-for-buttons {
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    &:not(:disabled) {
      cursor: pointer;
    }
  }
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}


input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  @include font-size(1.5rem);
  line-height: inherit;
  color: inherit; 
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

// Remove the inner padding in Chrome and Safari on macOS.
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

// Correct element displays
output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.paragraph {
  font-size: $font-size-default;
  line-height: $line-height-default;
  font-family: "poppinsregular";
}