.pointer {
  cursor: pointer;
  font-size: 18px;
}
.displayBlock {
  display: block;
}
.overlay {
  position: fixed;
  background: #0000002b;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
}

.profileCol {
  width: 300px;
  margin: auto;
}

.profileCoverPhotoImg {
  width: 30%;
  img {
    width: 66px;
    border-radius: 50%;
  }
}

.profileContentCol {
  width: 70%;
}

.headerShadow {
  box-shadow: 0px 30px 60px #97a6b529;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.notifacationBell {
  span {
    background-color: red;
    font-size: 10px;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 3px;
    right: 0;
    border-radius: 10px;
    color: #fff;
    padding: 1px 3px;;
  }
}
