// Base class
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;

  @include hover-focus() {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

// Tabs
.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-item {
    margin-bottom: -$nav-tabs-border-width;
  }

  .nav-link {
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus() {
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }

  .dropdown-menu {
    margin-top: -$nav-tabs-border-width;
    @include border-top-radius(0);
  }
}

// Pills
.nav-pills {
  .nav-link {
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

// Justified variants
.nav-fill {
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

// Tabbable tabs
.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

.nav-tabs-icon {
  .navbar-nav {
    flex-direction: inherit !important;
    .nav-link {
      font-size: 10px;
      color: $navbar-light-color;
      padding-top: $spacer / 3;
      padding-right: $spacer / 3;
      padding-bottom: $spacer * 1.3;
      padding-left: $spacer / 3;

      @include hover-focus() {
        color: $navbar-light-hover-color;
      }

      img {
        margin-top: $spacer;
        border-radius: $rounded-pill;
        border: (5 * $border-width) solid transparent;
        width: 60px;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
      font-weight: $font-weight-lg;
      border-bottom: (3 * $border-width) solid rgba($orange, 0.5);
      img {
        border: (5 * $border-width) solid rgba($orange, 0.3);
      }
    }
  }
}

.group-toggle-tabs {
  width: 84px;
  height: 35px;
  margin: auto;
  margin-bottom: $spacer * 1.5;
  border-radius: $rounded-pill;
  background-color: rgba($gray-400, 0.5);
  &.nav-pills {
    .nav-link {
      color: $gray-700 !important;
    }

    .nav-link.active,
    .show > .nav-link {
      color: $orange !important;
      background-color: $warning !important;
      &.first-child {
        border-top-left-radius: $rounded-pill !important;
        border-bottom-left-radius: $rounded-pill !important;
      }
      &.last-child {
        border-top-right-radius: $rounded-pill !important;
        border-bottom-right-radius: $rounded-pill !important;
      }
    }
  }
}

.total-fasting-hour-tabs {
  width: auto;
  margin: auto;
  margin-bottom: $spacer * 1.5;
  border-radius: $rounded-pill;
  padding: $spacer / 3;
  background-color: rgba($gray-400, 0.5);
  &.nav-pills {
    .nav-link {
      color: $gray-500 !important;
      font-weight: $font-weight-md;
      @include border-radius($nav-pills-border-radius);
    }

    .nav-link.active,
    .show > .nav-link {
      color: $gray-700 !important;
      background-color: $white;
      border-radius: $rounded-pill;
      box-shadow: $box-shadow;
    }
  }
}

.nav-auth-tabs {
  display: flex;
  align-items: center;
  .nav-item {
    font-size: 18px;
    font-weight: $font-weight-lg;
    color: $gray-900;
  }
  .nav-link {
    color: rgba($gray-900, 0.3);
  }
}
