/* The switch - the box around the slider */
label.switch {
    margin: 0rem !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;

    /* Hide default HTML checkbox */
    input {
        display: none;
    }
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray-300;
    transition: 0.4s;
    &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: $white;
        transition: 0.4s;
    }
}

input.default:checked + .slider {
    background-color: $secondary;
}
input.primary:checked + .slider {
    background-color: $primary;
}
input.success:checked + .slider {
    background-color: $success;
}
input.info:checked + .slider {
    background-color: $info;
}
input.warning:checked + .slider {
    background-color: $warning;
}
input.danger:checked + .slider {
    background-color: $danger;
}

input:focus + .slider {
    box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider {
    &.round {
        border-radius: 34px;
        &:before {
            border-radius: $rounded-pill;
        }
    }
}
