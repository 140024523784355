.spriteIcons {
  width: 24px;
  height: 24px;
  margin: auto;
  transform: scale(0.88);
  background: url("../../../assets/sprite-png-icons.svg") no-repeat;
}
.forYou {
  background-position: -3px -2px;
  &:hover,
  &:focus,
  &.active {
    background-position: -3px -32px;
  }
}
.weightLoss {
  background-position: -34px -3px;
  &:hover,
  &:focus,
  &.active {
    background-position: -34px -33px;
  }
}
.community {
  background-position: -63px -3px;
  &:hover,
  &:focus,
  &.active {
    background-position: -63px -33px;
  }
}
.fasting {
  background-position: -93px -3px;
  &:hover,
  &:focus,
  &.active {
    background-position: -93px -33px;
  }
}
.blog {
  background-position: -122px -3px;
  &:hover,
  &:focus,
  &.active {
    background-position: -122px -33px;
  }
}
