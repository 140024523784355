.carousel-active {
  position: relative;
  &:hover .owl-nav div {
    opacity: 1;
    visibility: visible;
  }
  .owl-nav div {
    position: absolute;
    left: 7px;
    top: 50%;
    font-size: $font-size-default;
    color: $gray-900;
    font-weight: $font-weight-normal;
    opacity: 0;
    visibility: hidden;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: $rounded-pill;
    background-color: $white;
    transform: translateY(-50%);
    border-color: rgba($black, 0.08);
    box-shadow: $box-shadow;
    transition: all 0.3s ease 0s;
    &.owl-next {
      left: auto;
      right: 7px;
    }
    &:hover {
      color: $black;
      background-color: $white;
      border-color: rgba($black, 0.08);
      box-shadow: 0px 2px 8px rgba($black, 0.25);
    }
  }

  .owl-dots {
    position: absolute;
    bottom: -$spacer * 1.5;
    left: 0;
    right: 0;
    z-index: 99;
    text-align: center;
  }
  .owl-dot {
    display: inline-block;
    height: 6px;
    width: 6px;
    margin: 0px 3px;
    transition: 0.3s;
    border-radius: $border-radius-xs;
    background-color: $gray-500;
    &.active {
      position: relative;
      background-color: rgba($black, 0.5);
      width: 6px;
    }
  }
  &.owl-carousel {
    .owl-nav.disabled {
      display: block;
    }
  }
}

.communities-carousel {
  &.owl-carousel {
    .owl-item {
      img {
        width: auto;
        margin: auto;
        text-align: center;
        box-shadow: $box-shadow;
        border: (5 * $border-width) solid $white;
        border-radius: $border-radius-album;
      }
    }
  }
  .listing-gallery {
    text-align: center;
  }
}

.carousel-active {
  padding-top: $spacer;
}

.communities-carousel,
.related-articles-carousel,
.suggested-products-carousel,
.cycle-history-carousel {
  &.carousel-active .owl-dots,
  .owl-carousel .owl-dots.disabled,
  .owl-carousel .owl-nav.disabled {
    display: none;
  }
}
