// accordion card
.accordion {
  > .card {
    > .card-header {
      > .accordion-header {
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        @include hover() {
          color: $link-hover-color;
          text-decoration: $link-decoration;
        }
        &:disabled,
        &.disabled {
          color: $btn-link-disabled-color;
          pointer-events: none;
        }
      }
    }
  }
}

// clikai accordion card with both left arrow and right arrow
.accordion,
.accordion-leftarrow,
.accordion-rightarrow {
  > .card {
    border: transparent;
    border-radius: $border-radius-album;
    border: (5 * $border-width) solid $white;
    box-shadow: $box-shadow-card;
    > .card-header {
      background-color: transparent;
      border: transparent;
      > .accordion-header {
        font-size: $font-size-default;
        font-weight: $font-weight-lg;
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        &.collapsed {
          .collapse-arrow {
            position: absolute;
            top: 50%;
            display: inline-block;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            svg,
            .fal {
              font-size: small;
              font-weight: 500;
              transform: rotate(0deg);
              -webkit-transition: -webkit-transform 0.24s;
              transition: -webkit-transform 0.24s;
              transition: transform 0.24s;
              transition: transform 0.24s, -webkit-transform 0.24s;
            }
          }
        }
        .collapse-arrow {
          position: absolute;
          top: 50%;
          display: inline-block;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          svg,
          .fal {
            font-size: small;
            font-weight: 500;
            transform: rotate(90deg);
            -webkit-transition: -webkit-transform 0.24s;
            transition: -webkit-transform 0.24s;
            transition: transform 0.24s;
            transition: transform 0.24s, -webkit-transform 0.24s;
          }
        }
        // hover
        @include hover() {
          color: $link-hover-color;
          text-decoration: $link-decoration;
        }
        &:disabled,
        &.disabled {
          color: $btn-link-disabled-color;
          pointer-events: none;
        }
        // header title text overflow ellipsis
        .card-header-title {
          display: inline-block;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          padding-right: 0.5rem;
          @include text-truncate();
        }
        // more links
        .card-header-link {
          @include float-right;
        }
      }
    }
    .collapse {
      background-color: $white;
      @include border-album-top-radius($card-album-border-radius);
    }
  }
  > .card:not(:last-of-type) {
    border-bottom: (5 * $border-width) solid $white;
    @include border-album-bottom-radius($card-album-border-radius);
  }
  > .card:not(:first-of-type) {
    @include border-album-top-radius($card-album-border-radius);
  }
}

// accordion card with left arrow
.accordion-leftarrow {
  > .card {
    > .card-header {
      padding-left: 2.3rem;
      background-color: $white;
      > .accordion-header {
        &.collapsed {
          .collapse-arrow {
            left: -1.2rem;
          }
        }
        .collapse-arrow {
          left: -1.2rem;
        }
      }
    }
  }
}

// accordion card with right arrow
.accordion-rightarrow {
  > .card {
    background-color: $light-cyan;
    > .card-header {
      background-color: transparent;
      > .accordion-header {
        &.collapsed {
          .collapse-arrow {
            right: 0.5rem;
          }
        }
        .collapse-arrow {
          right: 0.5rem;
        }
      }
    }
  }
}

// accordion card with up down arrow
.accordion-updownarrow {
  > .card {
    > .card-header {
      border-bottom: 1px solid transparent;
      background-color: $white;
      > .accordion-header {
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        &.collapsed {
          .collapse-arrow {
            position: absolute;
            top: 50%;
            right: 0.5rem;
            display: inline-block;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            svg,
            .fal {
              font-size: small;
              font-weight: 500;
              transform: rotate(90deg);
              -webkit-transition: -webkit-transform 0.24s;
              transition: -webkit-transform 0.24s;
              transition: transform 0.24s;
              transition: transform 0.24s, -webkit-transform 0.24s;
            }
          }
        }
        .collapse-arrow {
          position: absolute;
          top: 50%;
          right: 0.5rem;
          display: inline-block;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          svg,
          .fal {
            font-size: small;
            font-weight: 500;
            transform: rotate(-90deg);
            -webkit-transition: -webkit-transform 0.24s;
            transition: -webkit-transform 0.24s;
            transition: transform 0.24s;
            transition: transform 0.24s, -webkit-transform 0.24s;
          }
        }
      }
    }
    .card-body {
      padding-top: 0.8rem;
    }
  }
}

// accordion card
.accordion-plus-minus {
  > .card {
    > .card-header {
      padding-right: $spacer * 3;
      > .accordion-header {
        position: relative;
        &:before {
          content: "\f068";
          position: absolute;
          top: $spacer / 2;
          right: -$spacer * 1.5;
          transition: all 0.5s;
          font-family: "Font Awesome 5 Pro";
        }
        &.collapsed {
          &:before {
            content: "\f067";
            position: absolute;
            top: $spacer / 2;
            right: -$spacer * 1.5;
            transition: all 0.5s;
            font-family: "Font Awesome 5 Pro";
            // -webkit-transform: rotate(180deg);
            // -moz-transform: rotate(180deg);
            // transform: rotate(180deg);
          }
        }
      }
    }
  }
}
