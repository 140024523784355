// Base class
.list-group {
  display: flex;
  flex-direction: column;

  // No need to set list-style: none; since .list-group-item is block level
  padding-left: 0; // reset padding because ul and ol
  margin-bottom: 0;
}

// Interactive list items
.list-group-item-action {
  width: 100%; // For `<button>`s (anchors become 100% by default though)
  color: $list-group-action-color;
  text-align: inherit; // For `<button>`s (anchors inherit)

  // Hover state
  @include hover-focus() {
    z-index: 1; // Place hover/focus items above their siblings for proper border styling
    color: $list-group-action-hover-color;
    text-decoration: none;
    background-color: $list-group-hover-bg;
  }

  &:active {
    color: $list-group-action-active-color;
    background-color: $list-group-action-active-bg;
  }
}

// Individual list items
.list-group-item {
  position: relative;
  display: block;
  padding: $list-group-item-padding-y $list-group-item-padding-x;
  color: $list-group-color;
  background-color: $list-group-bg;
  border: $list-group-border-width solid $list-group-border-color;

  &:first-child {
    @include border-top-radius($list-group-border-radius);
  }

  &:last-child {
    @include border-bottom-radius($list-group-border-radius);
  }

  &.disabled,
  &:disabled {
    color: $list-group-disabled-color;
    pointer-events: none;
    background-color: $list-group-disabled-bg;
  }

  // Include both here for `<a>`s and `<button>`s
  &.active {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: $list-group-active-color;
    background-color: $list-group-active-bg;
    border-color: $list-group-active-border-color;
  }

  & + & {
    border-top-width: 0;

    &.active {
      margin-top: -$list-group-border-width;
      border-top-width: $list-group-border-width;
    }
  }
}

// Horizontal
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .list-group-horizontal#{$infix} {
      flex-direction: row;

      .list-group-item {
        &:first-child {
          @include border-bottom-left-radius($list-group-border-radius);
          @include border-top-right-radius(0);
        }

        &:last-child {
          @include border-top-right-radius($list-group-border-radius);
          @include border-bottom-left-radius(0);
        }

        &.active {
          margin-top: 0;
        }

        & + .list-group-item {
          border-top-width: $list-group-border-width;
          border-left-width: 0;

          &.active {
            margin-left: -$list-group-border-width;
            border-left-width: $list-group-border-width;
          }
        }
      }
    }
  }
}

// Flush list items
.list-group-flush {
  .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    @include border-radius(0);

    &:first-child {
      border-top-width: 0;
    }
  }

  &:last-child {
    .list-group-item:last-child {
      border-bottom-width: 0;
    }
  }
}

// Contextual variants
@each $color, $value in $theme-colors {
  @include list-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
}

// listing start here
.unordered-list {
  list-style: none;
  margin-block-start: 0rem;
  padding-inline-start: 0rem;
  li {
    position: relative;
    font-size: $font-size-xsmall;
    padding: $spacer / 2 0rem;
    padding-left: $spacer * 1.5 !important;
    &:before {
      content: "\f35a";
      position: absolute;
      left: 1px;
      top: 17px;
      color: rgba($primary, 0.7);
      text-align: center;
      font-weight: $font-weight-bold;
      transform: translate(-8%, -50%);
      font-family: "Font Awesome 5 Pro";
    }
  }
}

.list-group-rightarrow {
  .list-group-item {
    padding-right: $spacer * 3 !important;
    border: none;
    border-bottom: $list-group-border-width solid $list-group-border-color;
    div {
      h5 {
        color: #1D2433;
        font: normal normal 600 14px Poppins;
      }
    }
    p {
      color: #191919;
      font-weight: 300;
    }
  }
  .link-rightarrow {
    position: relative;
    &::after {
      content: "\f054";
      position: absolute;
      display: block;
      right: $spacer * 1;
      top: 50%;
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: $black;
      text-align: center;
      font-weight: $font-weight-bold;
      transform: translate(-8%, -50%);
      border-radius: $rounded-pill;
      background-color: #FED154;
      font-family: "Font Awesome 5 Pro";
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .card > .list-group:last-child .list-group-item:last-child {
    border: none;
  }
}

.list-group-bg-blue-rightarrow,
.list-group-bg-light-gold-400-rightarrow,
.list-group-normal {
  .list-group-item {
    padding-right: $spacer * 3 !important;
    border: none;
    border-bottom: $list-group-border-width solid $list-group-border-color;
    &:last-child {
      border-bottom-right-radius: 0rem !important;
      border-bottom-left-radius: 0rem !important;
      border-bottom: none !important;
    }
  }
  .link-rightarrow {
    position: relative;
    &::after {
      content: "\f054";
      position: absolute;
      display: block;
      right: $spacer;
      top: 50%;
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: $black;
      text-align: center;
      font-weight: $font-weight-bold;
      transform: translate(-8%, -50%);
      border-radius: $rounded-pill;
      background-color: $white;
      font-family: "Font Awesome 5 Pro";
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.list-group-bg-blue-rightarrow {
  .list-group-item {
    background-color: $light-cyan !important;
  }
}

.list-group-bg-light-gold-400-rightarrow {
  .list-group-item {
    background-color: $light-gold-400 !important;
  }
}

.list-group-faq {
  .list-group-item {
    padding: $spacer $spacer / 6 !important;
    border: none;
    background-color: transparent !important;
    border-bottom: $list-group-border-width solid $list-group-border-color;
    &:last-child {
      border-bottom-right-radius: 0rem;
      border-bottom-left-radius: 0rem;
      border-bottom: none;
    }
  }
  .link-rightarrow {
    position: relative;
    &::after {
      content: "\f178";
      position: absolute;
      display: block;
      right: $spacer / 5;
      top: 50%;
      color: $black;
      text-align: center;
      font-weight: $font-weight-bold;
      transform: translate(-8%, -50%);
      font-family: "Font Awesome 5 Pro";
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .card > .list-group:last-child .list-group-item:last-child {
    border: none;
  }
}

.list-group-item-first-column {
  ul {
    li {
      .col {
        width: 100%;
      }
    }
  }
}

.list-group-item-two-column {
  ul {
    li {
      .col {
        width: 50%;
      }
    }
  }
}

.list-group-item-three-column {
  ul {
    li {
      .col {
        width: 33.3333%;
      }
    }
  }
}

.list-group-item-fourth-column {
  ul {
    li {
      &:hover,
      &:focus,
      &.active,
      &.selectItem {
        background-color: rgba($white, 0.9) !important;
        color: $gray-900 !important;
      }
      .col {
        width: 25%;
      }
    }
  }
}

.list-group-item-first-column,
.list-group-item-two-column,
.list-group-item-three-column,
.list-group-item-fourth-column {
  width: 100%;
  font-size: $font-size-base;
  color: $gray-700;
  ul {
    width: 100%;
    padding: 0rem;
    margin: 0rem;
    li {
      clear: both;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: $spacer / 2;
      padding-bottom: $spacer / 2;
      background-color: rgba($gray-100, 0.1);
      border-bottom: (1 * $border-width) solid $gray-100;
      &:hover,
      &:focus,
      &.active,
      &.selectItem {
        transition-duration: 0.3s;
        background-color: rgba($primary, 0.5);
        cursor: pointer;
        color: $white;
      }
      .col {
        padding: 0rem;
        text-align: center;
      }
      a {
        text-decoration: $link-decoration;
        &:hover {
          text-decoration: $link-decoration;
        }
      }
    }
  }
}

.list-load-more {
  .list-group-item {
    border-top: none;
    border-right: none;
    border-left: none;
  }
  .list-group-item:first-child {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
  }
}

.list-group-normal {
  .list-group-item {
    padding: $spacer !important;
    &.active {
      background-color: $cyan-dark;
    }
  }
}

.list-group-scroll {
  max-height: 568px;
  overflow: auto;
}