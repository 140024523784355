// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

// Large Mobile (xs: 568px, md: 767px)
@media only screen and (min-width: 568px) and (max-width: 767px) {
}

// small mobile (xs: 320px, md: 567px)
@media only screen and (min-width: 320px) and (max-width: 567px) {
}

@media screen and (max-width: 767px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 736px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 667px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 640px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,  
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 568px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 480px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 414px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 411px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 384px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 375px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 360px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}

@media screen and (max-width: 320px) {
  .bodyComponent,
  .homePagesComponent,
  .headerComponent,
  .footerComponent,
  .fixed-bottom,
  .corePagesComponent,
  .appInnerWrapper {
    width: 100%;
  }
  .bgHealthyOptions {
    background-position-x: 125px;
  }
}
