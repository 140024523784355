.datepicker {
    .datepicker-content {
        padding: $spacer * 2 0;
    }
    .datepicker-scroll > li {
        height: 40px;
        line-height: 40px;
        font-size: $font-size-base;
        font-family: $font-family-base;
    }
    .datepicker-col-1 {
        margin: 0;
    }
    .datepicker-caption {
        display: none;
    }
    &.default {
        background-color: $white;
        @include border-album-top-radius($card-album-border-radius);
        .datepicker-wheel {
            height: 40px;
            line-height: 40px;
            background: $cyan-dark;
            border-color: $cyan-dark;
        }
        .datepicker-navbar {
            border-top: (1 * $border-width) solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
            flex-direction: row-reverse;
        }
        .datepicker-navbar-btn:first-child {
            border-left: (1 * $border-width) solid $border-color;
        }
        .datepicker-navbar-btn {
            padding: 0.8rem;
            margin: 0 4px;
            height: auto;
            line-height: initial;
            width: 100%;
            font-size: $font-size-default;
            text-transform: uppercase;
            color: $gray-700;
            font-weight: $font-weight-lg;
            font-family: $font-family-base;
        }
        .datepicker-header {
            display: none;
        }
    }
}

.calendar-rounded-shadow {
    box-shadow: 0px 20px 40px rgba($black, 0.16);
    border-radius: $border-radius-card;
}

// .rdrStartEdge {
//     left: 5px !important;
// }

// .rdrStartEdge,
// .rdrEndEdge {
//     top: 4px !important;
//     width: 32px;
//     height: 32px;
//     border-radius: $rounded-pill !important;
// }

.rdrMonth .rdrWeekDays {
    width: 100%;
    background-color: $gray-100;
    border-radius: $rounded-pill;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: $gray-900 !important;
    &:after {
        background-color: #1d2433 !important;
        height: 0px !important;
    }
}

.rdrNextPrevButton {
    &:hover {
        outline: none;
    }
}

.trackCyclePickerComponent {
    .rdrMonth {
        width: 23.667em;
    }
}