.img-fluid {
  @include img-fluid();
}

// Image thumbnails
.img-thumbnail {
  padding: $thumbnail-padding;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  @include border-radius($thumbnail-border-radius);
  @include box-shadow($thumbnail-box-shadow);

  // Keep them at most 100% wide
  @include img-fluid();
}

// Figures
.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: $spacer / 2;
  line-height: 1;
}

.figure-caption {
  @include font-size($figure-caption-font-size);
  color: $figure-caption-color;
}

.bg-flower,
.bg-healthy-options,
.bg-flower-blue-right,
.bg-flower-white-left {
  position: relative;
  &::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
  }
}

.bg-flower {
  background: url(../flower-bg.png) center $spacer * 1;
  background-repeat: no-repeat;
  background-size: auto;
}

.bg-healthy-options {
  z-index: 9;
  &::before {
    background: $blue-dark url(../healthy-options.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto;
  }
}

.bg-flower-blue-right {
  z-index: 9;
  &::before {
    background-image: url(../trace.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto;
  }
}

.bg-flower-white-left {
  z-index: 9;
  &::before {
    background-image: url(../trace.svg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto;
  }
}

.bg-cycle-circle {
  position: relative;
  width: 300px;
  height: 300px;
  background: url(../cycle-circle-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.bg-gray-circle {
  position: relative;
  width: 290px;
  height: 290px;
  background: url(../circle-gray-bg.svg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}

.bg-gray-body-status-circle {
  position: relative;
  width: 290px;
  height: 290px;
  background: url(../body-status-circle-bg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}

.bg-gray-body-status--with-blue-circle {
  position: relative;
  width: 290px;
  height: 290px;
  background: url(../circle-gray-with-blue-bg.svg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}

.card-item-img-shadow-rounded {
  .inviteFriendBtn {
    width: 100%;
    margin: auto;
    text-align: center;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
    border: 5px solid #ffffff;
    border-radius: 1rem;
    background: #d0f4ec;
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 90px;
    p {
      font-size: 0.9rem;
      padding: 0 1rem 0 0;
    }
    img {
      width: 60px;
    }
  }
}

.card-img-shadow-rounded {
  img {
    width: 36px;
    height: 36px;
    margin: auto;
    text-align: center;
    box-shadow: $box-shadow-xl;
    border-radius: $rounded-pill;
  }
}

.bg-flower {
  background: url(../flower-bg.png) center 1rem;
  background-repeat: no-repeat;
  background-size: auto;
}

.bg-flower-blue-right::before {
  background-image: url(../trace.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
}
.bg-flower-white-left::before {
  background-image: url(../trace.svg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: auto;
}

.gray-circle {
  position: relative;
  width: 290px;
  height: 290px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
}

.img-circle {
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: $rounded-pill;
  box-shadow: $box-shadow-pink;
  border: (1 * $border-width) solid $danger;
  img {
    width: 36px;
    border: (5 * $border-width) solid $white;
  }
}

.questionIcon {
  width: auto;
  img {
    width: 12px;
  }
}

.faqIcon {
  width: auto;
  float: left;
  margin-right: $spacer;
  img {
    width: 30px;
  }
}

.symbol {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: $border-radius-md;
  overflow: hidden;
  img {
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
  }
}

.listing-gallery {
  img {
    width: auto;
    margin: auto;
    text-align: center;
    box-shadow: $box-shadow;
    border: (5 * $border-width) solid $white;
    border-radius: $border-radius-album;
  }
}

.faqImg-small {
  width: auto;
  float: left;
  margin-right: $spacer;
  img {
    width: 48px;
  }
}

.img-small-circle {
  width: 60px;
  float: left;
  margin-right: $spacer;
  img {
    width: 48px;
    border-radius: $rounded-pill;
  }
}

.img52-small-circle {
  img {
    width: 52px;
    border-radius: $rounded-pill;
  }
}

.img72-small-circle {
  img {
    width: 72px;
    border-radius: $rounded-pill;
  }
}

.img250-big-rounded {
  img {
    height: 230px;
  }
}

.referAppComponentCard {
  width: 78px;
  height: 78px;
  img {
    width: 78px;
  }
}
