.bg-profile-header {
    position: relative;
    background-position: center;
    &:after,
    &:before {
        position: absolute;
        content: "";
        z-index: 0;
        width: 100%;
        height: 222px;
        display: block;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
        //background-color: rgba($black, 0.5);
        @include border-bottom-radius($modal-content-inner-border-radius);
    }
    .navbar {
        &.navbar-transparent {
            background-color: transparent !important;
            box-shadow: none !important;
            color: $white;
        }
        .navbar-nav .nav-item .nav-link {
            color: inherit;
        }
        .navbar-brand {
            color: inherit;
        }
    }
}

.profile-content {
    position: relative;
    z-index: 9;
    margin-top: $spacer * 10;
    .profile {
        text-align: center;
    }
    .profile img {
        max-width: 100px;
        width: 100%;
        margin: 0 auto;
        transform: translate3d(0, -40%, 0);
    }
}

.bg-profile-header header {
    background: transparent !important;
    color: $white !important;
}
