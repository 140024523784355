// Textual form controls
.form-control {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Remove select outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus($ignore-warning: true);

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  // Disabled and read-only inputs
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    opacity: 1;
  }
}

select.form-control {
  &:focus::-ms-value {
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

// Labels
.col-form-label {
  padding-top: add($input-padding-y, $input-border-width);
  padding-bottom: add($input-padding-y, $input-border-width);
  margin-bottom: 0;
  @include font-size(inherit);
  line-height: $input-line-height;
}

.col-form-label-lg {
  padding-top: add($input-padding-y-lg, $input-border-width);
  padding-bottom: add($input-padding-y-lg, $input-border-width);
  @include font-size($input-font-size-lg);
  line-height: $input-line-height-lg;
}

.col-form-label-sm {
  padding-top: add($input-padding-y-sm, $input-border-width);
  padding-bottom: add($input-padding-y-sm, $input-border-width);
  @include font-size($input-font-size-sm);
  line-height: $input-line-height-sm;
}

// Readonly controls as plain text
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: $input-padding-y 0;
  margin-bottom: 0;
  @include font-size($input-font-size);
  line-height: $input-line-height;
  color: $input-plaintext-color;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

// Form control sizing
.form-control-sm {
  height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  @include font-size($input-font-size-sm);
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

.form-control-lg {
  height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;
  @include font-size($input-font-size-lg);
  line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

// stylelint-disable-next-line no-duplicate-selectors
select.form-control {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea.form-control {
  height: auto;
}

// Form groups
.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}

// Form grid
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$form-grid-gutter-width / 2;
  margin-left: -$form-grid-gutter-width / 2;

  > .col,
  > [class*="col-"] {
    padding-right: $form-grid-gutter-width / 2;
    padding-left: $form-grid-gutter-width / 2;
  }
}

// Checkboxes and radios
.form-check {
  position: relative;
  display: block;
  padding-left: $form-check-input-gutter;
}

.form-check-input {
  position: absolute;
  margin-top: $form-check-input-margin-y;
  margin-left: -$form-check-input-gutter;

  &[disabled] ~ .form-check-label,
  &:disabled ~ .form-check-label {
    color: $text-muted;
  }
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: $form-check-inline-margin-x;

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;
  }
}

// Form validation
@each $state, $data in $form-validation-states {
  @include form-validation-state($state, map-get($data, color), map-get($data, icon));
}

// Inline forms
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  .form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group,
    .custom-select {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }
    .custom-control-label {
      margin-bottom: 0;
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $white;
  opacity: 1;
}

.input-border-rounded-pill {
  box-shadow: $box-shadow-light-gray !important;
  margin-bottom: $spacer / 3 !important;
  border-radius: $input-border-radius-pill !important;
  border: none !important;
}

.select-area {
  position: relative;
}
.form-inaari {
  .select-area {
    select {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      padding-right: $spacer * 2;
    }
  }
}
.select-area select {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select-area {
  &::after {
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    font-size: $font-size-base;
    text-align: center;
    right: $spacer;
    top: 0.7rem;
    cursor: pointer;
  }
}
.select-icon:after {
  content: "\f107";
}

.signup-home-form {
  width: 100%;
  margin: auto;
  height: 56px;
  padding: $spacer / 2;
  box-shadow: $box-shadow;
  background-color: $white;
  .signup-input {
    width: 70%;
    font-size: $font-size-small !important;
    display: inline-block;
    border: none;
    outline: none;
    box-shadow: none;
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  .signup-btn {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.upgrade-control {
  width: 100%;
  padding: $spacer / 2 $spacer * 1.5;
  font-weight: $input-font-weight;
  border-radius: $rounded-pill;
  background-color: $input-bg;
  box-shadow: $box-shadow;
  border: (2 * $border-width) solid transparent;
  &:hover,
  &:focus,
  &.selectItem {
    border: (2 * $border-width) solid $danger;
  }
}

.custom-radio-control {
  width: 100%;
  padding: $spacer / 2 $spacer * 1.5;
  font-weight: $input-font-weight;
  border-radius: $rounded-pill;
  background-color: $input-bg;
  box-shadow: $box-shadow;
}

.copyourLink {
  height: 50px !important;
  .form-control {
    width: 62% !important;
    padding-left: $spacer !important;
    padding-right: $spacer !important;
  }
}