.social-tabs {
    padding-top: $spacer;
    a {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: $rounded-pill;
        text-align: center;
        margin-right: $spacer / 2;
        transition: 0.2s linear;
        background-color: transparent;

        &:last-child {
            margin-right: 0rem;
            margin-bottom: 0rem;
        }
        &:hover {
            background-color: transparent;
            text-decoration: none;
            transform: scale(1.1);
        }
        &.fb {
            background-color: #2463a2;
        }
        &.twitter {
            background-color: #00b3e6;
        }
        &.linkedin {
            background-color: #0082be;
        }
        &.whatsapp {
            background-color: #00b15d;
        }
        &.tumblr {
            background-color: #24455e;
        }
        &.instagram {
            background-color: #faa94c;
            background: -webkit-gradient(linear, left top, right top, from(#faa94c), to(#8c4a9d));
            background: linear-gradient(50deg, #faa94c, #8c4a9d);
        }
    }

    i {
        font-size: $font-size-lg;
        color: $white;
        display: block;
        position: relative;
        z-index: 1;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }
    &:hover a i {
        color: $white;
    }
}
